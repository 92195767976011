import React, { useState, useEffect } from "react";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { USStates } from "../../datasource";
import { Counties } from "../../datasource";
import {
  DropDownListComponent,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { MAX } from "uuid";
import countiesData from "../../../counties_list.json";

const DisasterDetail = ({ propId }) => {
  const disasterId = propId;
  const [disasterData, setDisasterData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempData, setTempData] = useState({});
  const [lastEdited, setLastEdited] = useState(null);
  const [availableCounties, setAvailableCounties] = useState([]);

  useEffect(() => {
    const fetchDisaster = async () => {
      const id = Array.isArray(disasterId) ? disasterId[0] : disasterId;

      if (!id) {
        console.error("No valid ID provided to DisasterDetail component.");
        return;
      }

      try {
        const docRef = doc(db, "declared-disaster", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDisasterData({ id, ...docSnap.data() });
          setTempData({ id, ...docSnap.data() });
          setLastEdited(new Date().toLocaleString());
        }
      } catch (error) {
        console.error("Error fetching disaster data:", error);
      }
    };

    fetchDisaster();
  }, [disasterId]);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setTempData(disasterData);
  };

  const handleSaveClick = async () => {
    try {
      const id = Array.isArray(propId) ? propId[0] : propId;

      if (!id || typeof id !== "string") {
        console.error("Invalid document ID:", id);
        return;
      }

      const docRef = doc(db, "declared-disaster", id);
      await updateDoc(docRef, tempData);
      setDisasterData(tempData);
      setIsEditMode(false);
      setLastEdited(new Date().toLocaleString());
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

    // Update available counties when disaster_state changes
    useEffect(() => {
      if (tempData.disaster_state) {
        updateAvailableCounties(tempData.disaster_state);
      }
    }, [tempData.disaster_state]);
  
    // Update counties based on selected states
    const updateAvailableCounties = (selectedStates) => {
      if (!selectedStates || selectedStates.length === 0) {
        setAvailableCounties([]);
      } else {
        const filteredCounties = countiesData.filter((county) =>
          selectedStates.includes(county.State)
        );
        setAvailableCounties(filteredCounties.map((county) => county.County));
      }
    };

  const handleInputChange = (name, value) => {
    setTempData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatFirebaseDate = (firebaseDate) => {
    return firebaseDate && firebaseDate.seconds
      ? new Date(firebaseDate.seconds * 1000)
      : null;
  };

  if (!disasterData) {
    return <p>Loading disaster details...</p>;
  }

  return (
    <div className="w-full px-4">
      <header className="flex items-center justify-between gap-2">
        <p className="text-left text-sm font-bold text-blue-600">DETAILS</p>

        <div className="mr-3 flex items-center gap-2">
          {isEditMode ? (
            <>
              <button
                type="button"
                className="btn-default flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleSaveClick}
              >
                <FaSave className="h-3 w-3" /> Save
              </button>
              <button
                type="button"
                className="btn-cancel flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                onClick={handleCancelClick}
              >
                <FaTimes className="h-3 w-3" /> Cancel
              </button>
            </>
          ) : (
            <button
              type="button"
              className="btn-default flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleEditClick}
            >
              <FaEdit className="h-3 w-3" /> Edit
            </button>
          )}
        </div>
      </header>

      <div className="grid grid-cols-1 gap-4">
        <div className="flex w-full flex-col items-start justify-start gap-1">
          <div className="text-gray-600 relative text-left text-xs leading-[20px]">
            Name
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          </div>
          <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
            <TextBoxComponent
              name="disaster_name"
              value={tempData.disaster_name || ""}
              change={(e) => handleInputChange("disaster_name", e.value)}
              enabled={isEditMode}
              style={{
                backgroundColor: isEditMode ? "" : "#f3f4f6",
                color: "#000000",
                fontSize: "14px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              }}
              floatLabelType="Auto"
              cssClass="e-outline w-full"
            />
          </div>
        </div>

        <div className="flex w-full flex-col items-start justify-start gap-1">
          <div className="text-gray-600 relative text-left text-xs leading-[20px]">
            Description
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          </div>
          <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
            <TextBoxComponent
              name="description"
              value={tempData.description || ""}
              change={(e) => handleInputChange("description", e.value)}
              enabled={isEditMode}
              style={{
                backgroundColor: isEditMode ? "" : "#f3f4f6",
                color: "#000000",
                fontSize: "14px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              }}
              floatLabelType="Auto"
              cssClass="e-outline w-full"
            />
          </div>
        </div>

        <div className="flex w-full flex-col items-start justify-start gap-1">
          <div className="text-gray-600 relative text-left text-xs leading-[20px]">
            Type of Disaster
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          </div>
          <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
            <DropDownListComponent
              name="disaster_type"
              dataSource={[
                "Fire",
                "Flood",
                "Tornado",
                "Hurricane",
                "Earthquake",
              ]}
              value={tempData.disaster_type || ""}
              change={(e) => handleInputChange("disaster_type", e.value)}
              enabled={isEditMode}
              style={{
                backgroundColor: isEditMode ? "" : "#f3f4f6",
                color: "#000000",
                fontSize: "14px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              }}
              floatLabelType="Auto"
              cssClass="e-outline w-full"
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              Affected States
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <MultiSelectComponent
                name="disaster_state"
                dataSource={[
                  ...new Set(countiesData.map((item) => item.State)),
                ]}
                value={
                  Array.isArray(tempData.disaster_state)
                    ? tempData.disaster_state
                    : []
                }
                change={(e) => handleInputChange("disaster_state", e.value)}
                showSelectAll={true}
                allowFiltering={true}
                enabled={isEditMode}
                style={{
                  backgroundColor: isEditMode ? "" : "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>
          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              Affected Counties
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <MultiSelectComponent
                name="affected_counties"
                dataSource={availableCounties}
                mode="Box"
                value={
                  Array.isArray(tempData.affected_counties)
                    ? tempData.affected_counties
                    : []
                }
                change={(e) => handleInputChange("affected_counties", e.value)}
                showSelectAll={true}
                allowFiltering={true}
                enabled={isEditMode}
                style={{
                  backgroundColor: isEditMode ? "" : "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full gap-4">
          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              Declared By
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <TextBoxComponent
                name="declared_by"
                value={tempData.declared_by || ""}
                change={(e) => handleInputChange("declared_by", e.value)}
                enabled={false}
                style={{
                  backgroundColor: "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              Declared Date
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <DatePickerComponent
                name="date_declared"
                value={formatFirebaseDate(tempData.date_declared || "")}
                change={(e) => handleInputChange("date_declared", e.value)}
                enabled={false}
                style={{
                  backgroundColor: "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              Disaster Response and Relief
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <MultiSelectComponent
                name="declared"
                dataSource={["FEMA", "Red Cross", "State"]}
                value={
                  Array.isArray(tempData.declared) ? tempData.declared : []
                }
                change={(e) => handleInputChange("declared", e.value)}
                enabled={isEditMode}
                style={{
                  backgroundColor: isEditMode ? "" : "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full gap-4">
          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              Approved By
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <TextBoxComponent
                name="approved_by"
                value={tempData.approved_by || ""}
                change={(e) => handleInputChange("approved_by", e.value)}
                enabled={false}
                style={{
                  backgroundColor: "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              Approved Date
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <DatePickerComponent
                name="approved_date"
                value={formatFirebaseDate(tempData.approved_date || "")}
                change={(e) => handleInputChange("approved_date", e.value)}
                enabled={false}
                style={{
                  backgroundColor: "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col items-start justify-start gap-1">
          <div className="text-gray-600 relative text-left text-xs leading-[20px]">
            Stage of Disaster
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          </div>
          <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
            <DropDownListComponent
              name="disaster_stage"
              dataSource={["Response", "Recovery", "Relief"]}
              value={tempData.disaster_stage || ""}
              change={(e) => handleInputChange("disaster_stage", e.value)}
              enabled={isEditMode}
              style={{
                backgroundColor: isEditMode ? "" : "#f3f4f6",
                color: "#000000",
                fontSize: "14px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              }}
              floatLabelType="Auto"
              cssClass="e-outline w-full"
            />
          </div>
        </div>

        <div className="flex w-full gap-4">
          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              Start Date of Disaster
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <DatePickerComponent
                name="start_date"
                value={formatFirebaseDate(tempData.start_date || "")}
                change={(e) => handleInputChange("start_date", e.value)}
                enabled={isEditMode}
                style={{
                  backgroundColor: isEditMode ? "" : "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-1">
            <div className="text-gray-600 relative text-left text-xs leading-[20px]">
              End Date of Disaster
              <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                *
              </p>
            </div>
            <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
              <DatePickerComponent
                name="end_date"
                value={formatFirebaseDate(tempData.end_date || "")}
                change={(e) => handleInputChange("end_date", e.value)}
                enabled={isEditMode}
                style={{
                  backgroundColor: isEditMode ? "" : "#f3f4f6",
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
                }}
                floatLabelType="Auto"
                cssClass="e-outline w-full"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col items-start justify-start gap-1">
          <div className="text-gray-600 relative text-left text-xs leading-[20px]">
            Disaster Status
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          </div>
          <div className="box-border flex h-10 flex-row items-center justify-between self-stretch">
            <DropDownListComponent
              name="disaster_status"
              dataSource={["active", "inactive"]}
              value={tempData.disaster_status || ""}
              change={(e) => handleInputChange("disaster_status", e.value)}
              enabled={false}
              style={{
                backgroundColor: "#f3f4f6",
                color: "#000000",
                fontSize: "14px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              }}
              floatLabelType="Auto"
              cssClass="e-outline w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisasterDetail;
